/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      deviceID
      deviceName
      description
      deviceType
      deviceGroup
      serialNo
      swVersion
      hwVersion
      ipChgStatus
      ethIpAddress
      ethSubnet
      ethGateway
      ethDns
      swVerUpTrigger
      tallyIN {
        items {
          deviceID
          portID
          input
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tallyOUT {
        items {
          deviceID
          portID
          portPWM
          sourceDeviceID
          sourcePortID
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location {
        items {
          deviceID
          lat
          lng
          alt
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      deviceID
      deviceName
      description
      deviceType
      deviceGroup
      serialNo
      swVersion
      hwVersion
      ipChgStatus
      ethIpAddress
      ethSubnet
      ethGateway
      ethDns
      swVerUpTrigger
      tallyIN {
        items {
          deviceID
          portID
          input
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tallyOUT {
        items {
          deviceID
          portID
          portPWM
          sourceDeviceID
          sourcePortID
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location {
        items {
          deviceID
          lat
          lng
          alt
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      deviceID
      deviceName
      description
      deviceType
      deviceGroup
      serialNo
      swVersion
      hwVersion
      ipChgStatus
      ethIpAddress
      ethSubnet
      ethGateway
      ethDns
      swVerUpTrigger
      tallyIN {
        items {
          deviceID
          portID
          input
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tallyOUT {
        items {
          deviceID
          portID
          portPWM
          sourceDeviceID
          sourcePortID
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location {
        items {
          deviceID
          lat
          lng
          alt
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      deviceID
      lat
      lng
      alt
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      deviceID
      lat
      lng
      alt
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      deviceID
      lat
      lng
      alt
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTallyIN = /* GraphQL */ `
  mutation CreateTallyIN(
    $input: CreateTallyINInput!
    $condition: ModelTallyINConditionInput
  ) {
    createTallyIN(input: $input, condition: $condition) {
      deviceID
      portID
      input
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTallyIN = /* GraphQL */ `
  mutation UpdateTallyIN(
    $input: UpdateTallyINInput!
    $condition: ModelTallyINConditionInput
  ) {
    updateTallyIN(input: $input, condition: $condition) {
      deviceID
      portID
      input
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTallyIN = /* GraphQL */ `
  mutation DeleteTallyIN(
    $input: DeleteTallyINInput!
    $condition: ModelTallyINConditionInput
  ) {
    deleteTallyIN(input: $input, condition: $condition) {
      deviceID
      portID
      input
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTallyOUT = /* GraphQL */ `
  mutation CreateTallyOUT(
    $input: CreateTallyOUTInput!
    $condition: ModelTallyOUTConditionInput
  ) {
    createTallyOUT(input: $input, condition: $condition) {
      deviceID
      portID
      portPWM
      sourceDeviceID
      sourcePortID
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTallyOUT = /* GraphQL */ `
  mutation UpdateTallyOUT(
    $input: UpdateTallyOUTInput!
    $condition: ModelTallyOUTConditionInput
  ) {
    updateTallyOUT(input: $input, condition: $condition) {
      deviceID
      portID
      portPWM
      sourceDeviceID
      sourcePortID
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTallyOUT = /* GraphQL */ `
  mutation DeleteTallyOUT(
    $input: DeleteTallyOUTInput!
    $condition: ModelTallyOUTConditionInput
  ) {
    deleteTallyOUT(input: $input, condition: $condition) {
      deviceID
      portID
      portPWM
      sourceDeviceID
      sourcePortID
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
